<template>
  <div class="row justify-content-center bg-auth">
    <div
        class="col-12 col-md-5 col-lg-6 col-xl-4 px-lg-6 my-5 align-self-center"
    >
      <!-- Heading -->
      <div class="text-center">
        <img
            src="../../assets/img/logo/qevo_blue.svg"
            class="img-fluid"
            alt="..."
            width="240"
        />
      </div>

      <br>
      <h1 class="display-4 text-center mb-3">Entrar</h1>
      <!-- Subheading -->
      <p class="text-muted text-center mb-5">Informe seus dados para o login.</p>

      <!-- Email address -->
      <form @submit.prevent="login">
        <div class="form-group">
          <!-- Label -->
          <label class="form-label"> Endereço de E-mail </label>

          <!-- Input -->
          <input
              v-model="email"
              type="email"
              class="form-control"
              placeholder="name@mail.com"
              :class="Array.isArray(errors?.data?.errors?.user)? 'is-invalid' : ''"
          />
          <div v-if="Array.isArray(errors?.data?.errors?.user)" class="invalid-feedback">
            <span v-for="error in errors?.data?.errors?.user" :key="error">
              {{ error }}
            </span>
          </div>
        </div>

        <!-- Password -->
        <div class="form-group">
          <!-- Label -->
          <label class="form-label"> Senha </label>

          <!-- Input -->
          <input
              v-model="password"
              type="password"
              class="form-control"
              placeholder="Digite sua senha"
              :class="Array.isArray(errors?.data?.errors?.password) ? 'is-invalid' : ''"
          />

          <div v-if="Array.isArray(errors?.data?.errors?.password)" class="invalid-feedback">
            <span v-for="error in errors.data.errors.password" :key="error">
              {{ error }}
            </span>
          </div>

          <span v-if="errors?.status === 401" class="text-danger">
            Credenciais inválidas
          </span>
        </div>


        <!-- Submit -->
        <button class="btn btn-lg w-100 btn-primary mb-3">Entrar</button>
      </form>

      <!-- Link -->
      <p class="text-center">
        <small class="text-muted text-center">
          Ainda não tem uma conta?
          <router-link to="/signup">Registre-se</router-link>
        </small>
      </p>
      <p class="text-center">
        <small class="text-muted text-center">
          Esqueceu sua senha?
          <router-link to="/reset-password">Redefinir senha</router-link>
        </small>
      </p>
    </div>
    <div class="col-12 col-md-7 col-lg-6 col-xl-8 d-none d-lg-block">
      <!-- Image -->
      <div
          class="bg-cover h-100 min-vh-100 me-n3"
          :style="{
          'background-image':
            'url(' +
            require('../../assets/img/covers/unauthenticated_screen_3.png') +
            ')',
        }"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    msg: String,
  },

  data() {
    return {
      email: '',
      password: '',

      statusResponse: 0,
    }
  },

  computed: {
    errors() {
      return this.$store.getters['auth/getLoginErrors'];
    }
  },

  methods: {
    login() {
      this.$store.dispatch('auth/login', {
        user: this.email, password: this.password
      });

      this.password = '';
    }
  }
};
</script>

<style scoped>
.image-logo {
  width: 120px !important;
  height: 120px !important;
}
</style>
